<template>
  <div>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="12"
        md="10"
        lg="8"
        xl="7"
      >
        <v-card outlined>
          <v-card-text class="text-body-1">
            <v-row>
              <v-col cols="12">
                <v-btn
                  depressed
                  small
                  color="info"
                  @click="printInfo()"
                >
                  <v-icon left>
                    mdil-printer
                  </v-icon>
                  {{ $t('Print') }}
                </v-btn>
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <v-row>
                  <v-col cols="12">
                    <strong>{{ $t('Company Name') }}: </strong>
                    {{ authShareholder.company.name }}
                  </v-col>
                  <v-col cols="12">
                    <strong>{{ $t('Company Type') }}: </strong>
                    {{ authShareholder.company.company_type.name }}
                  </v-col>
                  <v-col cols="12">
                    <strong>{{ $t('Governorate') }}: </strong>
                    {{ authShareholder.company.governorate.name }}
                  </v-col>
                  <v-col cols="12">
                    <strong>{{ $t('First Bank') }}: </strong>
                    {{ authShareholder.company.first_bank.name }}
                  </v-col>
                  <v-col cols="12">
                    <strong>{{ $t('Account Bank') }}: </strong>
                    {{ authShareholder.company.account_bank.name }}
                  </v-col>
                  <v-col cols="12">
                    <strong>{{ $t('Business Name Date') }}: </strong>
                    {{ authShareholder.company.name_date }}
                  </v-col>
                  <v-col cols="12">
                    <strong>{{ $t('Business Name Number') }}: </strong>
                    {{ authShareholder.company.name_number }}
                  </v-col>
                  <v-col cols="12">
                    <strong>{{ $t('Capital') }}: </strong>
                    {{ $_number_format(authShareholder.company.capital) }}
                  </v-col>
                  <v-col cols="12">
                    <strong>{{ $t('Registration Number') }}: </strong>
                    {{ authShareholder.company.company_registration_number }}
                  </v-col>
                  <v-col cols="12">
                    <strong>{{ $t('Registration Date') }}: </strong>
                    {{ $_date_format(authShareholder.company.company_registration_date) }}
                  </v-col>
                  <v-col cols="12">
                    <strong>{{ $t('Dos Number In Companies') }}: </strong>
                    {{ authShareholder.company.dos_number_in_co }}
                  </v-col>
                  <v-col cols="12">
                    <strong>{{ $t('Dos Number In Tax Office') }}: </strong>
                    {{ authShareholder.company.dos_number_in_tax_office }}
                  </v-col>
                  <v-col cols="12">
                    <strong>{{ $t('SL') }}: </strong>
                    {{ authShareholder.company.sl }}
                  </v-col>
                  <v-col cols="12">
                    <strong>{{ $t('FCC') }}: </strong>
                    {{ authShareholder.company.fcc }}
                  </v-col>
                  <v-col cols="12">
                    <strong>{{ $t('Accountant Contract Date') }}: </strong>
                    {{ authShareholder.company.accountant_contract_date }}
                  </v-col>
                  <v-col cols="12">
                    <strong>{{ $t('Lawyer Contract Date') }}: </strong>
                    {{ authShareholder.company.lawyer_contract_date }}
                  </v-col>
                  <v-col cols="12">
                    <strong>{{ $t('Company Business No.') }}: </strong>
                    {{ authShareholder.company.company_business_no }}
                  </v-col>
                  <v-col cols="12">
                    <strong>{{ $t('Manager/Shareholder Business No.') }}: </strong>
                    {{ authShareholder.company.manager_shareholder_business_no }}
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <v-row>
                  <v-col cols="12">
                    <strong>{{ $t('Manager') }}: </strong>
                    {{ authShareholder.company.manager }}
                  </v-col>
                  <v-col cols="12">
                    <strong>{{ $t('Phone') }}: </strong>
                    {{ authShareholder.company.phone }}
                  </v-col>
                  <v-col cols="12">
                    <strong>{{ $t('Email') }}: </strong>
                    {{ authShareholder.company.email }}
                  </v-col>
                  <v-col cols="12">
                    <strong>{{ $t('Website') }}: </strong>
                    {{ authShareholder.company.website }}
                  </v-col>
                  <v-col cols="12">
                    <strong>{{ $t('Supporter Lawyer') }}: </strong>
                    {{ authShareholder.company.s_lawyer }}
                  </v-col>
                  <v-col cols="12">
                    <strong>{{ $t('Supporter Accountant') }}: </strong>
                    {{ authShareholder.company.s_accountant }}
                  </v-col>
                  <v-col cols="12">
                    <strong>{{ $t('Worker Lawyer') }}: </strong>
                    {{ authShareholder.company.w_lawyer }}
                  </v-col>
                  <v-col cols="12">
                    <strong>{{ $t('Worker Accountant') }}: </strong>
                    {{ authShareholder.company.w_accountant }}
                  </v-col>

                  <v-col cols="12">
                    <v-row
                      no-gutters
                      class="mt-3"
                    >
                      <v-col
                        cols="12"
                        class="font-weight-bold pb-2"
                      >
                        {{ $t('Shareholders') }}
                      </v-col>

                      <v-col
                        v-for="shareholder in shareholders"
                        :key="shareholder.id"
                        cols="12"
                        class="mt-1 mb-1"
                      >
                        <v-avatar size="34">
                          <img
                            :src="shareholder.profile_picture ? `${$_file_path()}${shareholder.profile_picture}?token=${$_auth_token()}`: $_profile_placeholder()"
                            :alt="shareholder.username"
                          >
                        </v-avatar>
                        {{ shareholder.first_name }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      shareholders: []
    }
  },

  computed: {
    ...mapGetters(['authShareholder'])
  },

  mounted() {
    this.getShareholders()
  },

  methods: {
    getShareholders() {
      axios.get('company/client/my-company-shareholders').then(res => {
        this.shareholders = res.data
      })
    }
  },
}
</script>

<style lang="scss" scoped>
</style>