<template>
  <div class="text-h3">
    هاوپێچەکان
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
</style>