import { render, staticRenderFns } from "./MyCompanyAttachments.vue?vue&type=template&id=418993cc&scoped=true&"
import script from "./MyCompanyAttachments.vue?vue&type=script&lang=js&"
export * from "./MyCompanyAttachments.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "418993cc",
  null
  
)

export default component.exports