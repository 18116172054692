<template>
  <div>
    <template v-if="authShareholder && authShareholder.company && authShareholder.company.id">
      <v-tabs
        v-model="tab"
        grow
        show-arrows
        class="rounded-lg"
        active-class="primary rounded"
        color="white"
      >
        <v-tab
          v-for="(item, index) in items"
          :key="index"
        >
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items
        v-model="tab"
        class="mt-5 rounded-lg pa-3"
        style="background-color: transparent"
      >
        <v-tab-item>
          <my-company-info />
        </v-tab-item>

        <v-tab-item>
          <my-company-accounting />
        </v-tab-item>

        <v-tab-item>
          <my-company-attachments />
        </v-tab-item>

        <v-tab-item>
          Seasonal & Yearly Form
          <!-- <seasonal-forms /> -->
        </v-tab-item>
      </v-tabs-items>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MyCompanyAccounting from './MyCompanyAccounting.vue'
import MyCompanyAttachments from './MyCompanyAttachments.vue'
import MyCompanyInfo from './MyCompanyInfo.vue'

export default {
  components: {
    MyCompanyAccounting,
    MyCompanyInfo,
    MyCompanyAttachments,
  },

  data() {
    return {
      tab: null,
      items: [
        this.$t('Info'),
        this.$t('Accounting'),
        this.$t('Attachments'),
        this.$t('Seasonal & Yearly Form'),
      ],
    }
  },

  computed: {
    ...mapGetters(['authShareholder'])
  },

  mounted() {
    this.$_section_title({ title: 'My Company' })
  },

  methods: {
    printInfo() {
      let content = {
        content: '',
        css: ''
      }

      content.content = `
        <div style="width: 100%" class="px-6" class="white">
          <table
            cellspacing="0"
            style="width: 100%; border: 1px solid #aaa; border-radius: 2px; border-bottom: 0px"
          >
            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Name')}:</b> ${this.authShareholder.company.name}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Company Type')}:</b> ${this.authShareholder.company.company_type.name}</td>
            </tr>
           
           <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Governorate')}:</b> ${this.authShareholder.company.governorate.name}</td>
              <td class="bottom-border pa-2"><b>${this.$t('First Bank')}:</b> ${this.authShareholder.company.first_bank.name}</td>
            </tr>
           
           <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Account Bank')}:</b> ${this.authShareholder.company.account_bank.name}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Business Name Date')}:</b> ${this.authShareholder.company.name_date}</td>
            </tr>
           
           <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Business Name Number')}:</b> ${this.authShareholder.company.name_number ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Capital')}:</b> ${this.$_number_format(this.authShareholder.company.capital)}</td>
            </tr>
           
           <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Registration Number')}:</b> ${this.authShareholder.company.company_registration_number ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Registration Date')}:</b> ${this.$_date_format(this.authShareholder.company.company_registration_date)}</td>
            </tr>

            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Manager')}:</b> ${this.authShareholder.company.manager ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Phone')}:</b> ${this.authShareholder.company.phone}</td>
            </tr>

            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Email')}:</b> ${this.authShareholder.company.email ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Website')}:</b> ${this.authShareholder.company.website ?? ''}</td>
            </tr>

            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Supporter Lawyer')}:</b> ${this.authShareholder.company.s_lawyer ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Supporter Accountant')}:</b> ${this.authShareholder.company.s_accountant ?? ''}</td>
            </tr>

            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Worker Lawyer')}:</b> ${this.authShareholder.company.w_lawyer ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Worker Accountant')}:</b> ${this.authShareholder.company.w_accountant ?? ''}</td>
            </tr>

            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Dos Number In Companies')}:</b> ${this.authShareholder.company.dos_number_in_co ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Dos Number In Tax Office')}:</b> ${this.authShareholder.company.dos_number_in_tax_office ?? ''}</td>
            </tr>

            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('SL')}:</b> ${this.authShareholder.company.sl ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('FCC')}:</b> ${this.authShareholder.company.fcc ?? ''}</td>
            </tr>

            <tr>
              <td colspan="2" class="bottom-border pa-2"><b>${this.$t('Activity')}:</b> ${this.authShareholder.company.activity ?? ''}</td>
            </tr>
          </table>
        </div>
      `

      content.css = `
        .bottom-border {
          border-bottom: 1px solid #aaa
        }
      `

      this.$_manual_print(content)
    }
  },
}
</script>

<style lang="scss" scoped>
</style>