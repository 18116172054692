var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"text-h6",attrs:{"cols":"12","sm":"auto"}},[_vm._v(" "+_vm._s(_vm.$t('Accounting Actions'))+" ")]),_c('v-col',{attrs:{"cols":"12","sm":"auto"}},[_c('v-btn',{class:{
                  'black white--text': !_vm.$vuetify.theme.dark,
                  'white black--text': _vm.$vuetify.theme.dark,
                },attrs:{"depressed":"","small":""},on:{"click":function($event){return _vm.$_print()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdil-printer ")]),_vm._v(" "+_vm._s(_vm.$t('Print'))+" ")],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-simple-table',{staticClass:"print-table",attrs:{"id":"print-area"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('invoice-header')],1),_c('tr',[_c('th',{staticClass:"text-subtitle-1 d-none d-print-table-cell font-weight-bold black--text",staticStyle:{"height":"30px"},attrs:{"colspan":"100"}},[_c('table',{staticStyle:{"width":"100%"}},[_c('tr',[_c('th',{staticClass:"text-start"},[_vm._v(" "+_vm._s(_vm.$t('Company Name'))+": "+_vm._s(_vm.authShareholder.company.name)+" ")]),_c('th',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.$t('Manager'))+": "+_vm._s(_vm.authShareholder.company.manager)+" ")])])])])]),_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v(_vm._s(_vm.$t('Date/Time')))]),_c('th',[_vm._v(_vm._s(_vm.$t('Type')))]),_c('th',[_vm._v(_vm._s(_vm.$t('Invoice No')))]),_c('th',{staticClass:"d-print-none"},[_vm._v(" "+_vm._s(_vm.$t('Invoice Picture'))+" ")]),_c('th',[_vm._v(_vm._s(_vm.$t('Description')))]),_c('th',[_vm._v(_vm._s(_vm.$t('Credit')))]),_c('th',[_vm._v(_vm._s(_vm.$t('Debt')))]),_c('th',[_vm._v(_vm._s(_vm.$t('Balance')))])])]),_c('tbody',_vm._l((_vm.apiData.data),function(row,accIndex){return _c('tr',{key:accIndex},[_c('td',[_vm._v(_vm._s(accIndex + 1))]),_c('td',{staticClass:"dir-ltr",class:{
                          'text-end': _vm.$vuetify.rtl,
                          'text-start': !_vm.$vuetify.rtl,
                        }},[_vm._v(" "+_vm._s(_vm.$_date_time_format(row.datetime))+" ")]),_c('td',[_c('v-chip',{attrs:{"label":"","small":"","color":_vm.getChipColor(row.action_type)}},[_vm._v(" "+_vm._s(_vm.$t(row.action_type))+" ")])],1),_c('td',[_vm._v(_vm._s(row.invoice_no))]),_c('td',{staticClass:"d-print-none"},[(row.attachments.length)?_c('a',{attrs:{"href":("" + (_vm.$_file_path()) + (row.attachments[0].attachment) + "?token=" + (_vm.$_auth_token())),"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("mdil-eye")])],1):_vm._e()]),_c('td',[_vm._v(_vm._s(row.description))]),_c('td',[_vm._v(_vm._s(_vm.$_number_format(row.credit)))]),_c('td',[_vm._v(_vm._s(_vm.$_number_format(row.debit)))]),_c('td',[_vm._v(_vm._s(_vm.$_number_format(row.balance)))])])}),0),_c('tfoot',[(_vm.apiData.totals)?_c('tr',{staticClass:"font-weight-bold grey",class:{
                        'darken-3': _vm.$vuetify.theme.dark,
                        'lighten-3': !_vm.$vuetify.theme.dark,
                      }},[_c('td',{attrs:{"colspan":"5"}}),_c('td',{staticClass:"d-print-none"}),_c('td',[_vm._v(_vm._s(_vm.$_number_format(_vm.apiData.totals.credit)))]),_c('td',[_vm._v(_vm._s(_vm.$_number_format(_vm.apiData.totals.debit)))]),_c('td',[_vm._v(_vm._s(_vm.$_number_format(_vm.apiData.totals.balance)))])]):_vm._e()])]},proxy:true}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }